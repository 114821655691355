import axios from "axios";

const baseUrl = "https://scoutsapi.bocky.be/api/leidingtak";
//const baseUrl = "http://localhost:9000/api/leidingtak";

const getJWTToken = async () => {
  const secret = process.env.REACT_APP_JWT_SECRET;
  try {
    const t = await axios.get(
      `https://scoutsapi.bocky.be/api/auth/generateToken?secret=${secret}`
    );
    return t.data.token;
  } catch (error) {
    console.error(error);
  }
};

const useLeidingTak = () => {
  const insertLeidingTak = async (leidingTakData) => {
    const token = await getJWTToken();
    try {
      await axios.post(baseUrl, leidingTakData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateLeidingTak = async (refid, leidingTakData) => {
    const token = await getJWTToken();
    try {
      await axios.put(`${baseUrl}/${refid}`, leidingTakData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteLeidingTak = async (id) => {
    const token = await getJWTToken();
    try {
      await axios.delete(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    insertLeidingTak,
    updateLeidingTak,
    deleteLeidingTak,
  };
};

export default useLeidingTak;
