import React, {useEffect, useState, useCallback} from 'react'
import { BsSearch } from 'react-icons/bs'
import {BsFillTrashFill} from 'react-icons/bs'
import {BsPencil} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import useHistory from '../../api/geschiedenis';
import Loader from '../rest/Loader'




const HistoryList = () => {

    const [data, setData] = useState([])
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [loading, setLoading] = useState(false)

    // api
    const historyApi = useHistory();


    // calling api
    const refreshHistory = useCallback(async () => {
        try{
            setLoading(true)
            const response = await historyApi.getAll();
            setData(response)
            setFilteredHistory(response)
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        refreshHistory()
    }, [refreshHistory])


    const filterHistory = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const filteredArray = data.history.filter((item) => {
          return (
            item.beginjaar.toString().includes(searchValue) ||
            item.titel.toLowerCase().includes(searchValue)
          );
        });
        setFilteredHistory({ history: filteredArray });
      };

      const HandleDeleteHistory = (id) => async () => {
        try {
            await historyApi.deleteHistory(id);
            refreshHistory();
        }
        catch(error){
            console.log(error)
        }
    }

   

    return (
        <div >
            <div className='upperPart-admin'>
                <div className='searchbar'>
                    <BsSearch className='searchIcon' />
                    <input className='searchbar-input' type="text" placeholder="Zoek jaar..." onChange={filterHistory} />
                </div>
                <Link to="/admin/kenteken/add" className='btn-save'>
                    Add +
                </Link>
            </div>      
            <div className='table-container'>    
            <table className='leiding-list'>
                <thead>
                    <tr>
                        <th>Kenteken</th>
                        <th>Jaar</th>
                        <th>Title</th>
                        <th></th>
                      
                        
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                    <Loader />
                    ) : (
                    filteredHistory.history?.map((history, index) => (
                        <tr key={index} className={`row-${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td>
                            <img src={history.kenteken} className='imgTable-kenteken' alt={history.titel} />
                        </td>
                        <td>{history.beginjaar} - {history.eindjaar}</td>
                        <td>{history.titel}</td>
                        <td className='action-table'>
                            <BsPencil className='editpencil' />
                            <BsFillTrashFill className='deletetrash' onClick={HandleDeleteHistory(history.id)} />
                        </td>
                        </tr>
                    ))
                    )}
                </tbody>
            </table>
            </div>
        </div>
    );
}

export default HistoryList;