import React, {useState, useEffect} from 'react'
import contact from '../../assets/contact.jpg'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { BsGeoAltFill, BsEnvelopeFill } from "react-icons/bs";
import "aos/dist/aos.css";
import AOS from "aos";

const Contact = () => {
    const [status , setStatus] = useState("");
    const form = useRef();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        window.scrollTo(0, 0)
      }, []);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_fq4s6ue', 'template_awinvmg', form.current, 'gT_gOA5lBtaFMtoJe')
          .then((result) => {
              setStatus("Bericht succesvol verzonden!")
          }, (error) => {
              setStatus("Probleem, probeer opnieuw")
          });
          e.target.reset()
      };
    return (
       <>
        <header>
            <img className='img-header' src={contact} style={{ objectPosition: 'center' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Contact</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        <div className='label-small'>Vragen? Contacteer ons!</div>
        <div className='info-page'>Heb je vragen over onze scouts? Of wil je meer informatie over onze scouts? Stuur ons dan gerust een mailtje!</div>
       
        <form data-aos="fade-up"  data-aos-duration="1000" ref={form} onSubmit={sendEmail} className="mailForm">
        <div className='rijForm'>
            <div className='colomForm'>
            <div className="inputlabel">Je naam:</div>
            <input
                placeholder="Jan Janssens"
                name="name"
                type="text"
                className="inputField"
                required
            />
            </div>
            <div className='colomForm'>
            <div className="inputlabel">Je e-mailadres:</div>
            <input
                placeholder="kapoentje@mail.com"
                name="email"
                type="email"
                className="inputField"
                required
            />
            </div>
        </div>
        <div className='rijForm'>
        <div className='colomForm'>
        <div className="inputlabel">Onderwerp:</div>
        <select name="category" className="inputFieldcat">
            <option value="algemeen">Algemeen</option>
            <option value="vraag">Vraag</option>
            <option value="inschijving">Inschijving</option>
            <option value="anders">Anders</option>
        </select>
        </div>
        <div className='colomForm'>
        <div className="inputlabel">Titel:</div>
            <input
                placeholder="Vraag over de scouts"
                name="subject"
                type="text"
                className="inputField"
                required
            />
        </div>
        </div>

        <div className='rijFormFull'>
            
            <div className="inputlabel">Je vraag:</div>
            <textarea
                placeholder="Je bericht..."
                name="text"
                className="inputFieldBig"
                required
            />
            <button
                type="submit"
                className="btnadd"
            >
                {' '}
                SEND
            </button>
            <div className={status === "Bericht succesvol verzonden!" ? "green" : "red"}>{status}</div>
            </div>
        </form>
        </main>
       </>
    )
}

export default Contact