import React, {useEffect, useState, useCallback} from 'react'
import scoutsgebouw from '../../assets/scoutsename_gebouw.jpg'
import LatestNews from '../newscomponents/LatestNews'
import NewsItem from '../newscomponents/NewsItem'
import BackSection from '../newscomponents/BackSection'
import AOS from "aos";
import "aos/dist/aos.css";
import useNieuws from '../../api/nieuws'
import Loader from '../rest/Loader'


const News = () => {
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


      const [data, setData] = useState([])
      const [latestNews, setLatestNews] = useState([])
      const [loading, setLoading] = useState(false)
  
      // api
      const newsApi = useNieuws();
  
  
      // calling api
      const refreshNews = useCallback(async () => {
          try{
              setLoading(true)
              const response = await newsApi.getAllNieuws();
                const laatst = response.events.data[0]
                setLatestNews(laatst)
                response.events.data.shift()
                setData(response.events.data);
          }
          catch(error){
              console.log(error)
          }     
          finally{
              setLoading(false)
          }
      }, [])
  
      useEffect(() => {
          refreshNews()
      }, [refreshNews])


    return (
       <>
        <header>
            <img className='img-header' style={{ objectPosition: 'top' }} src={scoutsgebouw} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Scouts Ename</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
            {/* Latest News */}
            {
                loading ? <Loader /> :
                <LatestNews item={latestNews}  />
            }

            {/* More news */}
            <div className='label-small'>Meer niews!</div>
            <div className='relative-container' data-aos="fade-up" data-aos-duration="1000">
                <div className='news-item-wrapper'>

                    {
                        loading ? <div>loading...</div> :
                        data.map((item) => (
                            <NewsItem key={item.id} item={item} />
                        ))
                    }
                </div>
            </div>
        </main>
        <BackSection />
       </>
    )
}

export default News