import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useRef } from 'react';
import {BsUpload} from 'react-icons/bs'
import Adminpic from '../../assets/admin.jpg'
import {BsArrowLeft} from 'react-icons/bs'
import useLeiding from '../../api/leiding';
import { useNavigate } from 'react-router-dom';
import useHistory from '../../api/geschiedenis';




const LeidingForm = () => {
    const [naam, setNaam] = useState('test')
    const [tak, setTak] = useState(1)
    const [tel, setTel] = useState('')
    const [foto, setFoto] = useState(null)
    const [fotonaam, setFotonaam] = useState('');
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const navigate = useNavigate();
    const leidingApi = useLeiding();

    const addLeiding = async () => {
       setUploading(true);
    const formData = new FormData();
        formData.append('name', naam);
        formData.append('image', file); // Append file as 'image'
        formData.append('tel', tel);
        formData.append('functie', tak);

    
       try {
         leidingApi.insertLeiding(formData);
         navigate('/admin');
    } catch (error) {
        console.log(error);
    }
    setUploading(false);
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setFile(file); // Store the file in state
            setFoto(file); // Set the file for preview (optional)
        }
    };


    const form = useRef();
    return (
        <form ref={form} onSubmit={addLeiding} className="mailForm">
        <div className='rijForm'>
            <div className='colomForm'>
            <div className="inputlabel">Naam:</div>
            <input
                placeholder="Jan Janssens"
                name="name"
                type="text"
                className="inputField"
                onChange={(e) => setNaam(e.target.value)}
                required
            />
            </div>
            <div className='colomForm'>
        <div className="inputlabel">Tak:</div>
        <select name="category" onChange={(e) => setTak(e.target.value)} className="inputFieldcat">
            <option value="1">Kapoenen</option>
            <option value="2">Welpen</option>
            <option value="3">Pioniers</option>
            <option value="4">Jongverkenners</option>
            <option value="5">Verkenners</option>
            <option value="6">Jin</option>          
        </select>
        </div>
          
        </div>
        <div className='rijForm'>
        <div className='colomForm'>
            <div className="inputlabel">Telefoon:</div>
            <input
                placeholder="+32 468 17 29 19"
                name="Tel"
                type="text"
                className="inputField"
                onChange={(e) => setTel(e.target.value)}
                required
            />
            </div>
        <div className='colomForm'>
        <div className="inputlabel">Foto:</div>
        <label for="file-upload" className="custom-file-upload">
           <BsUpload/> Upload File
        </label>
        <input id="file-upload" name='foto' type="file" 
        onChange={handleFileChange}/>
        </div>
       
        </div>
        {foto && <img src={URL.createObjectURL(foto)} alt='foto' className='exampleimage' />}
        <div className='rijFormFull'>
        
            <button
                type="submit"
                className="btnadd"
            >
                {' '}
                ADD
            </button>
            </div>
        </form>
    )
}


const HistoryForm = () => {
    const [naam, setNaam] = useState('test')
    const [embleemLink, setEmbleemLink] = useState('')
    const [beginjaar, setBeginjaar] = useState(2023)
    const [eindjaar, setEindjaar] = useState(2024)
    const [aalmoezenier, setAalmoezenier] = useState('')
    const [groepsleiding, setGroepsleiding] = useState('')
    const [tekst, setTekst] = useState('')
    const [uploading, setUploading] = useState(false);

    const navigate = useNavigate();
    const historyApi = useHistory();

    const getIdFromName = (name) => {
        return name.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-').toLowerCase();
    }

    const getBlocks = (text) => {
        const blocks = text.split('\n');
        return blocks.map(block => ({ artikel: block })); 
      };


    const addHistory = async () => {
       setUploading(true);
    const formData = new FormData();
        formData.append('id', getIdFromName(naam));
        formData.append('titel', naam);
        formData.append('beginjaar', beginjaar);
        formData.append('eindjaar', eindjaar);
        formData.append('aalmoezenier', aalmoezenier);
        formData.append('groepsleiding', groepsleiding);
        formData.append('kenteken', embleemLink);
    
       try {
         historyApi.insertHistory(formData);
         historyApi.insertHistoryTekst(getBlocks(tekst), getIdFromName(naam));
         navigate('/admin');
    } catch (error) {
        console.log(error);
    }
    setUploading(false);
    }


    const form = useRef();
    return (
        <form  ref={form} onSubmit={addHistory} className="mailForm">
        <div className='rijForm'>
            <div className='colomForm'>
            <div className="inputlabel">Kenteken:</div>
            <input
                placeholder="Titel"
                name="titel"
                type="text"
                className="inputField"
                onChange={(e) => setNaam(e.target.value)}
                required
            />
            </div>
           

        <div className='colomForm'>
        <div className="inputlabel">Embleem link:</div>
            <input
                placeholder="https://www.scoutsengidsenvlaanderen.be/sites/default/files/styles/1200w/public/images/Jaarthema"
                name="kenteken"
                type="text"
                className="inputField"
                onChange={(e) => setEmbleemLink(e.target.value)}
                required
            />
        </div>
          
        </div>
        <div className='rijForm'>
        <div className='colomForm'>
        <div className="inputlabel">Beginjaar:</div>
        <input name="beginjaar" type="number" placeholder='2023' className="inputField" onChange={(e) => setBeginjaar(e.target.value)} required/>
        </div>
        <div className='colomForm'>
            <div className="inputlabel">Eindjaar:</div>
            <input
                placeholder="2024"
                name="eindjaar"
                type="number"
                className="inputField"
                onChange={(e) => setEindjaar(e.target.value)}
                required
            />
            </div>
   
        </div>
        <div className='rijForm'>
            <div className='colomForm'>
                <div className="inputlabel">Aalmoezenier:</div>
                <input name="aalmoezenier" type="text" placeholder='Patrick Derde' className="inputField" onChange={(e) => setAalmoezenier(e.target.value)} required/>
            </div>
            <div className='colomForm'>
                <div className="inputlabel">Groepsleiding:</div>
                <input name="groepsleiding" type="text" placeholder='Ewout Bekaert, Elisabeth Cruz da Mota, Casper De Bock' className="inputField" onChange={(e) => setGroepsleiding(e.target.value)} required/>
            </div>
        </div>

        <div className='rijFormFull'>
        <div className='colomForm'>
        <div className="inputlabel">Geschreven tekst:</div>
        <textarea
                placeholder="Kapoenen gingen op kamp in Kapellen..."
                name="artikel"
                className="inputFieldBig"
                onChange={(e) => setTekst(e.target.value)}
                
            />
        </div>
            <button
                type="submit"
                className="btnadd"
            >
                ADD
            </button>
            </div>
        </form>
    )
}



const Add = () => {

    const { type } = useParams();
    const { category } = useParams();

    return (
        <>
        <header>
        <img className='img-header' style={{ objectPosition: 'top' }} src={Adminpic} alt='Gebouw Scouts Ename' />
        <div className='header-block'>
            <div className='header-flag' >
                <h1 className='header-title'>{type === "add" ? "Add " : "Edit "}{category}</h1>
            </div>
            <div className='header-subflag' >
                <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
            </div>
        </div>
    </header>
        <main>
        <Link to = {`/admin`} className='btn-back'><BsArrowLeft/></Link>
        {
            category === "leiding" ? <LeidingForm/> : category === "kenteken" ? <HistoryForm/> : null
        }
        
        </main>
        </>
    );
}

export default Add;