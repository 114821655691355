import React, { useEffect } from 'react'
import about from '../../assets/about.jpg'
import us from '../../assets/us.jpg'
import AOS from 'aos';
import "aos/dist/aos.css";
import BackSection from '../newscomponents/BackSection'


const About = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }
        , []);
    return (
       <>
        <header>
            <img className='img-header' src={about} style={{ objectPosition: 'bottom' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Over ons!</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        <div className='about-block' data-aos="fade-up"  data-aos-duration="500" >
            <div className='about-left-block'>
                <div className='label-small'>Over ons!</div>
                <h2 className='big-block-title top'>Scouts Ename</h2>
                <div className='about-info-block'>
                Elke zaterdagnamiddag houden wij, Scouts en Gidsen Sint-Laurentius Ename, vergadering van 14u tot 17u in onze lokalen in de Sint-Salvatorstraat. Je kan lid worden vanaf je zes jaar, (of zes worden voor 1 januari van het lopende werkjaar) of als je in het eerste leerjaar zit, tot je 18 jaar. Dan kan je leiding worden.
                </div>
                <div className='about-info-block'>
                Twijfel je nog of scouting we iets voor jou is? Dat is heel begrijpelijk en absoluut niet raar, want OK, we geven het toe:
                Scouts lijken soms nogal rare wezens: wie loopt er nu in de winter in korte broek te zingen? Maar eens je het sport en spel, de vriendschap hebt ervaren, wil je waarschijnlijk niks anders meer of wekelijks bij die toffe bende horen.
                </div>
                <div className='about-info-block'>
                Op de website van Scouts en Gidsen Vlaanderen vind je alle informatie over de scouts en haar werking.
                <a className='link' href="www.scoutsengidsenvlaanderen.be"> www.scoutsengidsenvlaanderen.be</a>
                </div>  
                <div className='label-small top'>Verhuur</div>
                <div className='about-info-block'>
                Helaas zijn we niet in staat onze lokalen te verhuren aan andere jeugdbewegingen of voor andere doeleinden.
                </div>
            </div>
            <div className='about-right-block'>
                <img className='about-right-img' src={us} alt='Scouts Ename' />
            </div>
        </div>
        

        
        </main>
        {/* <div>
            FOTO
        </div>
        <BackSection /> */}
       </>
    )
}

export default About