import React, { useEffect } from 'react'

import AOS from "aos";
import "aos/dist/aos.css";

const HistoryBlock = (props) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <a data-aos='fade-up' className='kenteken' data-aos-duration="500" href={`/geschiedenis/${props.history.id}`}>
            <div className='kenteken-img'><img src={props.history.kenteken} /></div>
            <div className='kenteken-info'>
                <div className='kenteken-title'>{props.history.titel}</div>
                <div className='kenteken-text'>{props.history.beginjaar} - {props.history.eindjaar}</div>
            </div>
        </a>
     
    )
}

export default HistoryBlock