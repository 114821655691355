import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/rest/Navbar";
import NotFound from "./components/rest/NotFound";
import Takken from "./components/pages/Takken";
import Footer from "./components/rest/Footer";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import News from "./components/pages/News";
import History from "./components/pages/History";
import HistoryDetails from "./components/pages/HistoryDetails";
import NewsDetail from "./components/pages/NewsDetail";
import TakkenDetail from "./components/pages/TakkenDetail";
import Admin from "./components/admin/Admin";
import Add from "./components/admin/Add";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<News />} />
        <Route path="/nieuws/:id" element={<NewsDetail />} />
        <Route path="/takken" element={<Takken />} />
        <Route path="/takken/:id" element={<TakkenDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/geschiedenis" element={<History />} />
        <Route path="/geschiedenis/:id" element={<HistoryDetails />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/:category/:type" element={<Add />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
