import React, {useEffect} from 'react'
import scoutsgebouw from '../../assets/scoutsename_gebouw.jpg'
import LatestNews from '../newscomponents/LatestNews'
import NewsItem from '../newscomponents/NewsItem'
import BackSection from '../newscomponents/BackSection'
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams } from 'react-router-dom';
import useNieuws from '../../api/nieuws'
import { useState, useCallback } from 'react';
import Loader from '../rest/Loader';


const NewsDetail = () => {
    const { id } = useParams();
    

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


      const [data, setData] = useState([])
      const [loading, setLoading] = useState(false)
  
      // api
      const newsApi = useNieuws();
  
      // calling api
      const refreshNews = useCallback(async () => {
          try{
              setLoading(true)
              const response = await newsApi.getNieuwsById(id);
              setData(response);
          }
          catch(error){
              console.log(error)
          }     
          finally{
              setLoading(false)
          }
      }, [])
  
      useEffect(() => {
          refreshNews()
      }, [refreshNews])


      const formatBelgianDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
       <>
        <header>
            <img className='img-header' style={{ objectPosition: 'top' }} src={scoutsgebouw} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Scouts Ename</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        {/* loading */}
        {loading ? <Loader /> : 
        <>
        {/* back button */}
        <div className='big-block'>
        <div className='big-block-left'>
                    <div className='label-small'>News!</div>
                    <div className='big-block-info'>
                        <h2 className='big-block-title'>{data["name"]}</h2>
                        <div className='big-block-text'></div>
                        <div>
                            <div><span className='aangeduid'>Datum:</span> {formatBelgianDate(data["start_time"])} </div>
                        </div>
                        <div className='label-small top'>Artikel</div>
                        <div >{data["description"]}</div>
                        {/* butttn to fb link */}
                        
                    </div>
                    <a href={`https://www.facebook.com/events/${data["id"]}`} target='_blank'><div className='big-block-btn'>Bekijk op Facebook</div></a>

            </div>
            <div className='big-block-right'>
                {data["cover"] ? (
                    <img className='big-block-img' src={`${data["cover"]}`} alt={data["name"]} />
                ) : (
                    <img src='https://sccvc.syd.catholic.edu.au/wp-content/uploads/sites/83/2019/05/Person-icon.jpg' className='big-block-img' alt={data["title"]} />
                )}
            </div>
        </div>
        </>}
        </main>
       </>
    )
}

export default NewsDetail