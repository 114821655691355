import React, {useState} from 'react';
import scoutsLogo from '../../assets/logoscouts.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [active, setActive] = useState("Niews");
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
       <nav>
         <Link onClick={()=> setActive("Nieuws")} to="/" className="nav-img">
              <img src={scoutsLogo} className='nav-logo' alt="Scouts logo" />  
        </Link>
        <div className="nav-bar">
            <ul className='nav-bar-list'>
                <div className='nav-bar-left'>
                    <li  className={active === "Nieuws" ? "nav-bar-item activeNav" : 'nav-bar-item'}><Link onClick={()=> setActive("Nieuws")} to="/">Nieuws</Link></li>
                    <li className={active === "Takken" ? "nav-bar-item activeNav" : 'nav-bar-item'}><Link onClick={()=> setActive("Takken")}  to="/takken">Takken</Link></li>
                    <li  className={active === "About" ? "nav-bar-item activeNav" : 'nav-bar-item'}><Link onClick={()=> setActive("About")} to="/about">Over ons</Link></li>
                    <li className={active === "History" ? "nav-bar-item activeNav" : 'nav-bar-item'}> <Link  onClick={()=> setActive("History")} to="/geschiedenis">Geschiedenis</Link></li>
                </div>
                <div className='nav-bar-right'>
                    <li  className='nav-bar-item part-right'><Link onClick={()=> setActive("Contact")} to="/contact">Contact</Link></li>
                </div>
                
            </ul>

            <div className={!isOpen ? "nav-toggle" : "nav-toggle-open"} onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </div>  

        {isOpen && (
                <div className="nav-menu-mobile">
                    <ul>
                        <Link onClick={() => { setActive("Nieuws"); setIsOpen(false); }} to="/">
                            <li className={active === "Nieuws" ? "nav-bar-item activeNav" : 'nav-bar-item'}>Nieuws</li>
                        </Link>
                        <Link onClick={() => { setActive("Takken"); setIsOpen(false); }} to="/takken">
                            <li className={active === "Takken" ? "nav-bar-item activeNav" : 'nav-bar-item'}>Takken</li>
                        </Link>
                        <Link onClick={() => { setActive("About"); setIsOpen(false); }} to="/about">
                            <li className={active === "About" ? "nav-bar-item activeNav" : 'nav-bar-item'}>Over ons</li>
                        </Link>
                        <Link onClick={() => { setActive("History"); setIsOpen(false); }} to="/geschiedenis">
                            <li className={active === "History" ? "nav-bar-item activeNav" : 'nav-bar-item'}>Geschiedenis</li>
                        </Link>
                        <Link onClick={() => { setActive("Contact"); setIsOpen(false); }} to="/contact">
                            <li className='nav-bar-item'>Contact</li>
                        </Link>
                    </ul>
                </div>
            )}
       </nav>
 
       </>

    )

}

export default Navbar;