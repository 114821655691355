import axios from "axios";

const baseUrl = "https://scoutsapi.bocky.be/api/takken";
//const baseUrl = "http://localhost:9000/api/takken";

const getJWTToken = async () => {
  const secret = process.env.REACT_APP_JWT_SECRET;
  try {
    const t = await axios.get(
      `https://scoutsapi.bocky.be/api/auth/generateToken?secret=${secret}`
      //`http://localhost:9000/api/auth/generateToken?secret=${secret}`
    );
    return t.data.token;
  } catch (error) {
    console.error(error);
  }
};

const useTakken = () => {
  const getAllTakken = async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const insertTak = async (takData) => {
    const token = await getJWTToken();
    try {
      await axios.post(baseUrl, takData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTak = async (id) => {
    const token = await getJWTToken();
    try {
      await axios.delete(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateBoekjeById = async (id, formData) => {
    const token = await getJWTToken();
    try {
      const res = await axios.put(`${baseUrl}/boekje/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateTak = async (id, takData) => {
    const token = await getJWTToken();
    try {
      await axios.put(`${baseUrl}/${id}`, takData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getTakById = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getAllTakken,
    insertTak,
    deleteTak,
    updateBoekjeById,
    updateTak,
    getTakById,
  };
};

export default useTakken;
