import axios from "axios";

const baseUrl =
  "https://graph.facebook.com/v21.0/scoutsename?fields=events{description,cover,start_time,name,id}";

const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
const useNieuws = () => {
  const getAllNieuws = async () => {
    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getNieuwsById = async (id) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v21.0/scoutsename?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      //get image
      const image = await axios.get(
        `https://graph.facebook.com/v21.0/scoutsename?fields=cover&id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      response.data.cover = image.data.cover.source;
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getAllNieuws,
    getNieuwsById,
  };
};

export default useNieuws;
