import React from 'react'
import takken from '../../assets/takken.jpg'
import Tak from '../takcomponents/Tak'
import kapoenensvg from '../../assets/taklogos/kapoenen.jpg'
import welpensvg from '../../assets/taklogos/welpen.jpg'
import pionierssvg from '../../assets/taklogos/pios.png'
import jongverkennersvg from '../../assets/taklogos/jvk.jpg'
import verkennerssvg from '../../assets/taklogos/vk.jpg'
import jinsvg from '../../assets/taklogos/jin.jpg'
import leidingsvg from '../../assets/taklogos/leiding.jpg'
import groepsleidingsvg from '../../assets/taklogos/groepsleiding.png'
import mastworp from '../../assets/taklogos/mastworp.jpg'
import { Link } from 'react-router-dom'

const Takken = () => {
    return (
       <>
        <header>
            <img className='img-header' src={takken} style={{ objectPosition: 'center' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Takken</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        <div className='label-small'>Onze takken!</div>
        <div className='info-page'>Leeftijdsgroepen, bij Scouts en Gidsen Vlaanderen heten ze takken. Welke takken zijn er? En waarom kiest Scouts en Gidsen Vlaanderen net voor deze leeftijdsopdeling?</div>
        <div className='label-small top bottom'>De takken!</div>
        <div className='takken-container'>
            <Link className='nolinkstyle' to={'/takken/kapoenen'}> <Tak naam='Kapoenen' leeftijd='6-8' img={kapoenensvg} color="#DCEF07" /></Link>
            <Link className='nolinkstyle' to={'/takken/welpen'}> <Tak naam='Welpen' leeftijd='8-10' img={welpensvg} color="#4EBDB6" /></Link>
            <Link className='nolinkstyle' to={'/takken/pioniers'}> <Tak naam='Pioniers' leeftijd='10-12' img={pionierssvg} color="#296B07"  /></Link>
            <Link className='nolinkstyle' to={'/takken/jongverkenners'}> <Tak naam='Jongverkenners' leeftijd='12-14' img={jongverkennersvg} color="#FB8B05" /></Link>
            <Link className='nolinkstyle' to={'/takken/verkenners'}> <Tak naam='Verkenners' leeftijd='14-17' img={verkennerssvg} color="#0D29D3" /></Link>
            <Link className='nolinkstyle' to={'/takken/jin'}> <Tak naam='Jin' leeftijd='17-18' img={jinsvg} color="#B1031C" /></Link>
            <Link className='nolinkstyle' to={'/takken/leiding'}> <Tak naam='Leiding' leeftijd='18+' img={leidingsvg} color="#730CCD" /> </Link>
            <Link className='nolinkstyle' to={'/takken/groepsleiding'}> <Tak naam='Groepsleiding' leeftijd='18+' img={groepsleidingsvg} color="#542D00" /></Link>
            <Link className='nolinkstyle' to={'/takken/mastworp'}> <Tak naam='Mastworp' leeftijd='18+' img={mastworp} color="#D94B38" /></Link>

        </div>
        </main>
       </>
    )
}

export default Takken