import React, {useEffect, useState} from 'react'
import history from '../../assets/history.jpg'
import { useParams } from 'react-router-dom';
import useHistory from '../../api/geschiedenis';
import Loader from '../rest/Loader';
import { useCallback } from 'react';


const HistoryDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState([])
    const [articleData, setArticleData] = useState([])
    const [loading, setLoading] = useState(true)

    // api
    const historyApi = useHistory();

    // calling api
    const refreshHistory = useCallback(async () => {
        try{
            setLoading(true)
            const response = await historyApi.getHistoryById(id);
            console.log(response)
            setData(response)
            const response2 = await historyApi.getArticleByHistoryId(id);
            setArticleData(response2)
            console.log(response2)
            console.log(response)
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }
    , [])

    useEffect(() => {
        refreshHistory()
    }, [refreshHistory])

    return (
        <>
        <header>
            <img className='img-header' src={history} style={{ objectPosition: 'center' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    { loading ? <Loader /> :
                    <>
                    <h1 className='header-title'>{data[0].titel}</h1>
                    </>
                    }
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
       <main>

         { loading ? <Loader /> : 
         <>
        <div className='big-block'>
        <div className='big-block-left'>
                    <div className='label-small'>Informatie</div>
                    <div className='big-block-info'>
                        <h2 className='big-block-title'>{data[0].titel}</h2>
                        <div className='big-block-text'></div>
                        <div>
                            <div><span className='aangeduid'>Jaar:</span> {data[0].beginjaar} - {data[0].eindjaar}</div>
                            <div><span className='aangeduid'>Aalmoezenier:</span> {data[0].aalmoezenier}</div>
                            <div><span className='aangeduid'>Groepsleiding:</span> {data[0].groepsleiding}</div>
                        </div>
                        <div className='label-small top'>Geschreven tekst</div>
                        {
                            articleData.map((item, index) => {
                                return <div key={index} className='big-block-text'>{item.artikel}</div>
                            })
                        }
                    </div>
            </div>
            <div className='big-block-right-kenteken'>
                <img className='nota big-block-img ' src={data[0].kenteken} alt={data[0].titel}/>
            </div>
        </div>
        
        </>
        }
          
         </main>
         </>
    )
}

export default HistoryDetails