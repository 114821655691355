import React from 'react'

const BackSection = () => {
    return (
      <div className='back-section'>
        
      </div>
    )
}

export default BackSection