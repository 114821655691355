import axios from "axios";

const baseUrl = "https://scoutsapi.bocky.be/api";
//const baseUrl = "http://localhost:9000/api";

const getJWTToken = async () => {
  const secret = process.env.REACT_APP_JWT_SECRET;
  try {
    const t = await axios.get(
      `https://scoutsapi.bocky.be/api/auth/generateToken?secret=${secret}`
      //`http://localhost:9000/api/auth/generateToken?secret=${secret}`
    );
    return t.data.token;
  } catch (error) {
    console.error(error);
  }
};

const useHistory = () => {
  const getAll = async () => {
    try {
      const response = await axios.get(`${baseUrl}/geschiedenis`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const insertHistory = async (historyData) => {
    const token = await getJWTToken();
    try {
      const historyResponse = await axios.post(
        `${baseUrl}/geschiedenis`,
        historyData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return historyResponse.data;
    } catch (error) {
      console.log(error);
    }
  };

  const insertHistoryTekst = async (blokken, id) => {
    const token = await getJWTToken();
    console.log(blokken);
    console.log(id);
    for (let i = 0; i < blokken.length; i++) {
      const blok = blokken[i];
      blok.geschiedenisId = id;
      console.log(blok);
      try {
        await axios.post(`${baseUrl}/geschiedenis/articles`, blok, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getArticleByHistoryId = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/geschiedenis/articles/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHistory = async (id) => {
    const token = await getJWTToken();
    try {
      await axios.delete(`${baseUrl}/geschiedenis/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateHistory = async (id, historyData) => {
    const token = await getJWTToken();
    try {
      await axios.put(`${baseUrl}/geschiedenis/${id}`, historyData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getHistoryById = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/geschiedenis/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getAll,
    insertHistory,
    deleteHistory,
    updateHistory,
    getArticleByHistoryId,
    getHistoryById,
    insertHistoryTekst,
  };
};

export default useHistory;
