import React from 'react'
import kapoenen from '../../assets/takkendetails/kapoenen.jpg'
import welpen from '../../assets/takkendetails/welpen.jpg'
import pioniers from '../../assets/takkendetails/pioniers.jpg'
import jongverkenners from '../../assets/takkendetails/jongverkenners.jpg'
import verkenners from '../../assets/takkendetails/verkenners.jpg'
import jin from '../../assets/takkendetails/jin.jpg'
import leiding from '../../assets/takkendetails/leiding.jpg'
import groepsleiding from '../../assets/takkendetails/groepsleiding.jpg'
import mastworp from '../../assets/takkendetails/mastworp.jpg'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import infotak from '../../data/takdescription.json'
import useLeiding from '../../api/leiding'
import useTakken from '../../api/takken'
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import kapoenensvg from '../../assets/taklogos/kapoenen.jpg'
import welpensvg from '../../assets/taklogos/welpen.jpg'
import pionierssvg from '../../assets/taklogos/pios.png'
import jongverkennersvg from '../../assets/taklogos/jvk.jpg'
import verkennerssvg from '../../assets/taklogos/vk.jpg'
import jinsvg from '../../assets/taklogos/jin.jpg'
import leidingsvg from '../../assets/taklogos/leiding.jpg'
import groepsleidingsvg from '../../assets/taklogos/groepsleiding.png'
import mastworpsvg from '../../assets/taklogos/mastworp.jpg'
import {BsBook} from 'react-icons/bs'
import Loader from '../rest/Loader'





const TakkenDetail = () => {

    //params
    const { id } = useParams();
    const info = infotak.data.find(taknaam => taknaam.name === id)
    const [leidinginfo, setLeidinginfo] = useState([])
    const [takinfo, setTakinfo] = useState([])
    const [loading, setLoading] = useState(false)

    //api
    const leidingApi = useLeiding();
    const takkenApi = useTakken();



    //formatting
    const headerpicture = id === "kapoenen" ? kapoenen : id === "welpen" ? welpen : id === "pioniers" ? pioniers : id === "jongverkenners" ? jongverkenners : id === "verkenners" ? verkenners : id === "jin" ? jin : id === "leiding" ? leiding : id === "groepsleiding" ? groepsleiding : mastworp
    const title = id === "kapoenen" ? "Kapoenen" : id === "welpen" ? "Welpen" : id === "pioniers" ? "Pioniers" : id === "jongverkenners" ? "Jongverkenners" : id === "verkenners" ? "Verkenners" : id === "jin" ? "Jin" : id === "leiding" ? "Leiding" : id === "groepsleiding" ? "Groepsleiding" : "Mastworp"
    const nieuwebeschrijving = info.beschrijving.replace(/<br>/g, '<br/><br/>')
    const sidepic = id === "kapoenen" ? kapoenensvg : id === "welpen" ? welpensvg : id === "pioniers" ? pionierssvg : id === "jongverkenners" ? jongverkennersvg : id === "verkenners" ? verkennerssvg : id === "jin" ? jinsvg : id === "leiding" ? leidingsvg : id === "groepsleiding" ? groepsleidingsvg : mastworpsvg
    const idnr = id === "kapoenen" ? 1 : id === "welpen" ? 2 : id === "pioniers" ? 3 : id === "jongverkenners" ? 4 : id === "verkenners" ? 5 : id === "jin" ? 6 : 7

    // calling api
    const refreshData = async () => {
        try{
            setLoading(true)
            const response = await leidingApi.getAllLeidingFromTaken(id);
            setLeidinginfo(response)
            if(idnr < 7){
                const response2 = await takkenApi.getTakById(idnr)
                converteerBoekjes(response2)
                setTakinfo(response2)
            }
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }

    const converteerBoekjes = (tak) => {
        if(tak.boekje){
            const byteCharacters = atob(tak.boekje);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
            
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
            
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                tak.boekje = url

        }
    }

    const formated = (id) => {
        const monthNames = [
            "Januari", "Februari", "Maart", "April", "Mei", "Juni",
            "Juli", "Augustus", "September", "Oktober", "November", "December"
        ];
        
        const currentMonth = monthNames[new Date().getMonth()];
        switch (id) {
            case 1:
                return `Boekje Kapoenen (${currentMonth})`;
            case 2:
                return `Boekje Welpen (${currentMonth})`;
            case 3:
                return `Boekje Pioniers (${currentMonth})`;
            case 4:
                return `Boekje Jongverkenners (${currentMonth})`;
            case 5:
                return `Boekje Verkenners (${currentMonth})`;
            case 6:
                return `Boekje Jin (${currentMonth})`;
            default:
                return '';
        }
    }



    const refreshLeiding = async () => {
        try{
            setLoading(true)
            const response = await leidingApi.getAllLeiding();
            setLeidinginfo(response)
        }
        catch(error){
            console.log(error)
        }
        finally{
            setLoading(false)
        }
    }

    useEffect(() => {
      
        if(id === "leiding"){
            refreshLeiding()
        }
        else{
            refreshData()
        }
        window.scrollTo(0, 0)
    }, [])

    const Image = ({fotonaam, altnaam}) => (
        <img className='leiding-img' src={`data:image/jpg;base64,${fotonaam}`} alt={altnaam} />
    )
    
   

    return (
       <>
        <header>
            <img className='img-header' src={headerpicture} style={ id==="mastworp"? {objectPosition:'top'} : id==="jin"? {objectPosition:'top'} : {  objectPosition: 'center' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>{title}</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        <div className='container-upper-tak'>
            <div className='info-tak'>
                <div className='label-small'>De {title}!</div>
                <div className='samen sm-top'>
                    <div className='leiding-icon'><BsPeople/></div>
                    <div >{info.leeftijd}</div>
                </div>
                <div className='samen'>
                    <div className='leiding-icon'><BsEnvelope/></div>
                    <div >{info.email}</div>
                </div>
                { idnr < 7 &&
                <div className='samen'>
                    <div className='leiding-icon'><BsBook/></div>
                    <div><a target="_blank" className='boekje-link' href={takinfo.boekje}>{formated(takinfo.id)}</a></div>
                </div>
                }
                <div className='bold sm-top'>Info</div>
                <div className='info-page-tak' dangerouslySetInnerHTML={{ __html: nieuwebeschrijving }}></div>
                { id !== "mastworp" &&
                <div className='label-small top bottom'>{title !== "Mastworp"? "De Leiding!" : "De leden:"}</div>
                }
            </div>
                <img className='tak-img-b' src={sidepic} alt={id} />
         
        </div>
        

        <div className='leiding-container'>
            {loading ? (
                <Loader />
            ) : (
                leidinginfo
                    .sort((a, b) => {
                        const aIsTakleiding = a.takken.some(tak => tak.tak_name === "Takleiding");
                        const bIsTakleiding = b.takken.some(tak => tak.tak_name === "Takleiding");
                        if (aIsTakleiding && !bIsTakleiding) {
                            return -1;
                        } else if (!aIsTakleiding && bIsTakleiding) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    .map((element) => {
                        let showleiding = false;
                        element.takken.map((tak) => {
                            if (tak.tak_name.toUpperCase() === id.toUpperCase()) {
                                showleiding = true;
                            }
                        });
                        if (showleiding) {
                            return (
                                <div className='leiding-item'>
                                    {element.imagename ? (
                                            <Image fotonaam={element.imageData} altnaam={element.name} />
                                            ) : (
                                            <img className='leiding-img' src='https://sccvc.syd.catholic.edu.au/wp-content/uploads/sites/83/2019/05/Person-icon.jpg' alt={element.name} />
                                            )}
                                    <div className='leiding-info'>
                                      <div className='leiding-naam bold'>{element.name}</div>
                                        {element.takken.map((tak) => {
                                            
                                            if (tak.tak_name === "Takleiding" || tak.tak_name === "Groepsleiding") {
                                                return (
                                                    <>
                                                        <div className='leiding-tak'>{tak.tak_name}</div>
                                                        <div className='leiding-bottom-info'>
                                                            <BsTelephone className='leiding-icon' />
                                                            <div className='leiding-tel'>{element.tel}</div>
                                                        </div>
                                                    </>
                                                );
                                            } 
                                        })}
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })
            )}

            {
                id === "leiding" &&
                leidinginfo
                    .sort((a, b) => {
                        const takOrder = ["Kapoenen", "Welpen", "Pioniers", "Jongverkenners", "Verkenners", "Jin"];
                        const aTakIndex = takOrder.indexOf(a.tak_name);
                        const bTakIndex = takOrder.indexOf(b.tak_name);
                        return aTakIndex - bTakIndex;
                    })
                    .map((element) => {
                        return (
                            <div className='leiding-item'>
                                {element.imagename ? (
                                    <Image fotonaam={element.imageData} altnaam={element.name} />
                                ) : (
                                    <img className='leiding-img' src='https://sccvc.syd.catholic.edu.au/wp-content/uploads/sites/83/2019/05/Person-icon.jpg' alt={element.name} />
                                )}
                                <div className='leiding-info'>
                                    <div className='leiding-naam bold'>{element.name}</div>
                                    <div className={`leiding-tak pad ${element.takken[0].tak_name}`}>{element.takken[0].tak_name}</div>
                                </div>
                            </div>
                        );
                    })
            }
        </div>
       
        </main>
       </>
    )
}

export default TakkenDetail