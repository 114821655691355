import React, {useEffect, useState, useCallback} from 'react'
import { BsSearch } from 'react-icons/bs'
import {BsFillTrashFill} from 'react-icons/bs'
import {BsPencil} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import useTakken from '../../api/takken';
import {BsUpload} from 'react-icons/bs'
import kapoenensvg from '../../assets/taklogos/kapoenen.jpg'
import welpensvg from '../../assets/taklogos/welpen.jpg'
import pionierssvg from '../../assets/taklogos/pios.png'
import jongverkennersvg from '../../assets/taklogos/jvk.jpg'
import verkennerssvg from '../../assets/taklogos/vk.jpg'
import jinsvg from '../../assets/taklogos/jin.jpg'
import Loader from '../rest/Loader'

const BoekjeList = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [filename, setFilename] = useState('')
    

    // api
    const takkenApi = useTakken();


    // calling api
    const refreshBoekje = useCallback(async () => {
        try{
            setLoading(true)
            const response = await takkenApi.getAllTakken();
            // i only want the takken from id 1 - 6
            const fitlered = response.takken.filter((tak) => {
                return (
                    tak.id < 7
                )
            })
            converteerBoekjes(fitlered)
            setData(fitlered)
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        refreshBoekje()
    }, [refreshBoekje])

    const converteerBoekjes = (takken) => {
        takken.forEach((tak) => {
            if(tak.boekje){
                const byteCharacters = atob(tak.boekje);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
                
                    for (let i = 0; i < slice.length; i++) {
                      byteNumbers[i] = slice.charCodeAt(i);
                    }
                
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                  }
                  const blob = new Blob(byteArrays, { type: "application/pdf" });
                    const url = URL.createObjectURL(blob);
                    tak.boekje = url

            }
        })
    }


    const handleFileChange = async (id, e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('boekje', file); // Directly send the file as 'boekje'
      
        try {
          await takkenApi.updateBoekjeById(id, formData);
          await refreshBoekje();
        } catch (error) {
          console.error(error);
        }
      };

    const formated = (id) => {
        const monthNames = [
            "Januari", "Februari", "Maart", "April", "Mei", "Juni",
            "Juli", "Augustus", "September", "Oktober", "November", "December"
        ];
        
        const currentMonth = monthNames[new Date().getMonth()];
        switch (id) {
            case 1:
                return `Boekje Kapoenen (${currentMonth})`;
            case 2:
                return `Boekje Welpen (${currentMonth})`;
            case 3:
                return `Boekje Pioniers (${currentMonth})`;
            case 4:
                return `Boekje Jongverkenners (${currentMonth})`;
            case 5:
                return `Boekje Verkenners (${currentMonth})`;
            case 6:
                return `Boekje Jin (${currentMonth})`;
            default:
                return '';
        }
    }
    
    
    

    return (
        <div >
            <div className='upperPart-admin'>
               
            </div>         
            <div className='table-container'>
            <table className='leiding-list'>
                <thead>
                    <tr>
                        <th>Teken</th>
                        <th>Tak</th>
                        <th>Huidig boekje</th>
                        <th>Upload</th>
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                    <Loader />
                    ) : (
                    data.map((tak, index) => (
                        <tr key={index} className={`row-${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td><img src={tak.id === 1 ? kapoenensvg : tak.id === 2 ? welpensvg : tak.id === 3 ? pionierssvg : tak.id === 4 ? jongverkennersvg : tak.id === 5 ? verkennerssvg : tak.id === 6 ? jinsvg : ''} alt='tak' className='imgTable-kenteken-tak'/></td>
                        <td>{tak.name}</td>
                       {<td>  <a target="_blank" className='boekje-link' 
                        href={tak.boekje}>{formated(tak.id)}</a></td>} 
                        <td className=''>
                        <label for={tak.id} className="custom-file-upload-t">
                            <BsUpload/> Upload File
                            </label>
                            <input
                            className='upload-f'
                            id={tak.id}
                            name='foto'
                            type='file'
                            onChange={(e) => handleFileChange(tak.id, e)}
                        />
                        </td>
                        </tr>
                    ))
                    )}
                </tbody>
            </table>
            </div>
        </div>
    );
}

export default BoekjeList;