import React from 'react'
import {BsInstagram} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsGeoAltFill} from 'react-icons/bs'
import { BsEnvelopeFill } from 'react-icons/bs'
import scoutslogowhite from '../../assets/scoutsgidsenvl.png'
import Pdf from '../../assets/gdpr.pdf'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
      <footer>
        <div className='upper-footer'>
          <ul>
            <li><a target='_blank'rel="noopener noreferrer" href="https://groepsadmin.scoutsengidsenvlaanderen.be/groepsadmin/client/">Groepsadministratie</a></li>
            <li><a target='_blank'rel="noopener noreferrer" href='https://www.hopper.be/nl/'>Hopper</a></li>
            <li><Link to='/about'>Over Scouts Ename</Link></li>
          </ul>
          <ul>
            <li><Link to='/geschiedenis'>Geschiedenis</Link></li>
            <li><Link to='/contact'>Contact</Link></li>
            <li><a href ={Pdf} target = "_blank">GDPR</a></li>

          </ul>
          <ul>
            <li>Volg ons</li>
            <li>
              <div className='socials'>
                <a target='_blank ' rel="noopener noreferrer" href='https://www.facebook.com/ScoutsEname'><BsFacebook className='social-icon'/></a>
                <a target='_blank' rel="noopener noreferrer" href='https://twitter.com/ScoutsGidsenVL' ><BsTwitter className='social-icon'/></a>
                <a target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/scoutsename/'><BsInstagram className='social-icon'/></a>
              </div>
            </li>
          </ul>
        </div>
        <div className='down-footer'>
            <div className='down-footer-left'>
              <div className='down-footer-info'>
                <div className='down-footer-info-img'>
                  <img src={scoutslogowhite} alt='Scouts en Gidsen Vlaanderen' className='down-footer-logo'/>
                </div>
                <div className='down-footer-info-text'>
                  <div className='samen'><BsGeoAltFill/> <a target='_blank' rel="noopener noreferrer" className='underline' href="https://www.google.com/maps/place/Scoutsterrein+Scouts+en+Gidsen+Ename/@50.8560185,3.6263969,17z/data=!3m1!4b1!4m6!3m5!1s0x47c30e173c715891:0x419b8492c47b0439!8m2!3d50.8560185!4d3.6289718!16s%2Fg%2F1td_gdjs?entry=ttu">Sint-salvatorstraat 0 9700 Oudenaarde</a></div>
                  <div className='samen'><BsEnvelopeFill/><Link className='no' to="/contact">groepsleiding.scoutsename@gmail.com</Link></div>
                  <a className='no' target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/ScoutsEname'><div className='samen'><BsFacebook className=''/> Vind ons ook op facebook</div></a>
                </div>
              </div>
            </div>
            <div className='down-footer-right'>
              <img src="https://www.scoutsengidsenvlaanderen.be/sites/default/files/styles/medium/public/images/hopper_logo_wit_liggend.png?itok=Tr7Td_mm" alt='Hopper logo' className='down-footer-hopper'/>
              <img src="https://www.scoutsengidsenvlaanderen.be/sites/default/files/styles/medium/public/images/vlaanderen-verbeelding-werkt.png?itok=xsasDQEQ" alt='Vlaanderen verbeelding werkt' className='down-footer-vlaanderen'/>
            </div>
        </div>
        <div className='dev-by'>
          <div className='copyright'>
            © {new Date().getFullYear()} Scouts en Gidsen Vlaanderen vzw ®
          </div>
          <div className='dev-by-text'>
            <a className='underline' target='_blank' rel="noopener noreferrer" href='https://bocky.be'>Developed by<u className='devlink'>bocky.</u></a>
        </div>
        </div>
      </footer>
    )
}

export default Footer