import React from 'react'
import history from '../../assets/history.jpg'
import beden from '../../assets/beden.webp'
import AOS from "aos";
import "aos/dist/aos.css";
import { useState, useCallback, useEffect } from 'react';
import HistoryBlock from '../historycomponenten/HistoryBlock';
import useHistory from '../../api/geschiedenis';
import Loader from '../rest/Loader';

const History = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    AOS.init();
    AOS.refresh();

    // api
    const historyApi = useHistory();

    // calling api
    const refreshHistory = useCallback(async () => {
        try{
            setLoading(true)
            const response = await historyApi.getAll();
            setData(response)
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        refreshHistory()
    }, [refreshHistory])


    return (
        <>
        <header>
            <img className='img-header' src={history} style={{ objectPosition: 'center' }} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Geschiedenis</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
        <div className='about-block' data-aos="fade-up"  data-aos-duration="500" >
            <div className='about-left-block'>
                <div className='label-small'>Onze geschiedenis!</div>
                <h2 className='big-block-title top'>De Geschiedenis</h2>

                <div className='about-info-block'>
                Het kan niet worden ontkend: Scouting Ename heeft een rijk verleden achter zich en wellicht ook nog een mooie toekomst voor zich! In dit onderdeel van de site kunt u wat meer lezen over deze rijke geschiedenis.                </div>
                <div className='about-info-block'>
                U vindt hier de geschiedenis van 1956 tot 1995 zoals ze door Guido Tack werd neergeschreven voor een brochure die werd uitgegeven ter gelegenheid van 45 jaar Scouting Ename.
                </div>
                <div className='about-info-block'>
                De resterende jaren werden sindsdien telkenmale neergeschreven door een aantal leiders en leden van de Mastworp Ename.
                </div>  
                <div className='about-info-block'>
                Per scoutsjaar kunt u nalezen wie toen aalmoezenier, wie groepsleiding was, waar de kampen naartoe gingen en wat de jaarleuze was. Ook de verschillende kentekens zijn hier te bezichtigen.
                </div>  
                <div className='about-info-block'>
                We zijn er ons van bewust dat er op deze pagina's vast nog een aantal geschiedkundige foutjes zullen voorkomen. Vooreerst onze excuses daarvoor en ook ons vriendelijk verzoek om dit te melden in het <a className='link' href='/contact'>contact</a> form. Zo krijgt iedereen een correct beeld uit de, toch wel kleurrijke geschiedenis van onze jeugdbeweging.
                </div>
            </div>
            <div className='history-right-block'>
                <img className='history-right-img' src={beden} alt='Scouts Ename' />
            </div>
        </div>
        <div className='kentekens-block'>
            <div className='label-small top bottom'>Kentekens</div>
            <div className='kentekens-container'>
            {
                loading ? <Loader/> : data.history?.map((history, index) => {
                    return <HistoryBlock key={index} history={history} />
                })
            }
            </div>
        </div>
         </main>
         </>
    )
}

export default History