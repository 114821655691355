import React, {useEffect, useState} from 'react'
import Adminpic from '../../assets/admin.jpg'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import { BsArchive } from "react-icons/bs";
import {BsBook} from 'react-icons/bs'
import LeidingList from './LeidingList'
import HistoryList from './HistoryList'
import BoekjeList from './BoekjeList'



const Admin = () => {
    const [activeTab, setActiveTab] = useState('leiding')
    const [isAuthenticated, setIsAuthenticated] = useState(false);


    useEffect(() => {
        const password = prompt("Please enter the password to access the admin panel:");
        if (password === process.env.REACT_APP_SECRET_PASSWORD) {
            setIsAuthenticated(true);
        } else {
            alert("Incorrect password. Access denied.");
        }
    }, []);

    if (!isAuthenticated) {
        return <div>Access Denied</div>;
    }
    return (
       <>
        <header>
            <img className='img-header' style={{ objectPosition: 'top' }} src={Adminpic} alt='Gebouw Scouts Ename' />
            <div className='header-block'>
                <div className='header-flag' >
                    <h1 className='header-title'>Admin Panel</h1>
                </div>
                <div className='header-subflag' >
                    <div className='header-subtitle'>Scouts en Gidsen Sint-Laurentius Ename</div>
                </div>
            </div>
        </header>
        <main>
            <div className='admin-nav'>
                <div onClick={() => setActiveTab("leiding")} className={activeTab === 'leiding' ? 'activeAdmin admin-nav-item':'admin-nav-item'}><BsFillPersonLinesFill/></div>
                <div onClick={() => setActiveTab("history")} className={activeTab === 'history' ? 'activeAdmin admin-nav-item':'admin-nav-item'}><BsArchive/></div>
                <div onClick={() => setActiveTab("boekje")} className={activeTab === 'boekje' ? 'activeAdmin admin-nav-item':'admin-nav-item'}><BsBook/></div>
            </div>
            {
                activeTab === 'leiding' ? <LeidingList/>: activeTab === 'history' ? <HistoryList/> : activeTab==="boekje" ? <BoekjeList/> : null
            } 
        </main>
       </>
    )
}

export default Admin