import React from 'react'
import { Link } from 'react-router-dom'

const NewsItem = (props) => {
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

      const Image = ({fotonaam, altnaam}) => (
        <img className='news-item-img' src={fotonaam} alt={altnaam} />
    )
    
    return (
        <Link to={`/nieuws/${props.item.id}`} className='news-item-link'>
            <div className='news-item'>
                <div className='news-item-top'>
                {props.item.cover ? (
                                    <Image fotonaam={props.item.cover.source} altnaam={props.item.name} />
                                ) : (
                                    <img src='https://sccvc.syd.catholic.edu.au/wp-content/uploads/sites/83/2019/05/Person-icon.jpg' className='news-item-img' alt={props.item.title} />
                                )}
                </div>
                <div className='news-item-bottom'>
                    <div className='news-item-title'>{props.item.name}</div>
                    <div className='news-item-date'>{formatDate(props.item.start_time)}</div>
                    <div className='news-item-text'>{props.item.description.length > 50 ? `${props.item.description.slice(0, 150)}...` : props.item.description}</div>
                    <div className='news-item-btn'>Meer info</div>
                </div>
            </div>
        </Link>
    )
}

export default NewsItem