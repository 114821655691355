import React, { useEffect } from 'react'

import AOS from "aos";
import "aos/dist/aos.css";

const Tak = (props) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div data-aos='fade-up' data-aos-duration="500">
            <div className='tak'style={{ borderBottom: `2px solid ${props.color}` }} >
            <img className='tak-img' src={props.img} alt='Tak'/>
            <div className='tak-info'>
                <div className='tak-title'>{props.naam}</div>
                <div className='tak-text'>Leeftijd: {props.leeftijd}</div>
            </div>
        </div>
        </div>
     
    )
}

export default Tak