import React from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'

const LatestNews = (props) => {
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
      const Image = ({fotonaam, altnaam}) => (
        <img className='big-block-img' src={fotonaam} alt={altnaam} />
    )
    
    
    return (
        <Link to={`/nieuws/${props.item.id}`} className='news-item-link'>
        <div className='big-block'  data-aos="fade-up" data-aos-duration="500">
            <div className='big-block-left'>
                <div className='label-small'>Laatste nieuws!</div>
                <div className='big-block-info'>
                    <h2 className='big-block-title'>{props.item.name}</h2>
                    <div className='news-item-date'>{formatDate(props.item.start_time)}</div>
                    <div className='big-block-text'>{props.item.description ? `${props.item.description.slice(0, 500)}...` : props.item.description}</div>

                    <div className='big-block-btn'>Meer info</div>
                </div>
            </div>
            <div className='big-block-right'>
                        {props.item.cover ? (
                                    <Image fotonaam={props.item.cover.source} altnaam={props.item.name} />
                                ) : (
                                    <img src='https://sccvc.syd.catholic.edu.au/wp-content/uploads/sites/83/2019/05/Person-icon.jpg' className='big-block-img' alt={props.item.name} />
                                )}
            </div>
            
        </div>
        </Link>
    )
}

export default LatestNews